import { Typography, Box } from "@mui/material";

//components
import LabelValue from "./components/LableValue";

// redux
import { useAppSelector } from "redux-config/hook";
import ApiFetchStates from "constants/apiFetchStates";

// utils
import formatLargeNumber from "feature/dashboard/utils/formatNum";

function CustomerInteraction() {
  const states = useAppSelector((state) => state.dashboardFeature.businessInfo);
  return (
    <Box>
      <Typography fontSize="30px" fontWeight={600}>
        Customer Interaction
      </Typography>

      {/* Spacing */}
      <Box mt={5}></Box>
      <Typography fontSize="20px" fontWeight={600}>
        Weekly
      </Typography>
      <LabelValue
        loading={states.apiFetchStatus === ApiFetchStates.Loading}
        label="Views in app:"
        value={formatLargeNumber(states.todayViewInApp)}
        unit="potential customers"
      />
      <LabelValue
        loading={states.apiFetchStatus === ApiFetchStates.Loading}
        label="In-store check ins:"
        value={formatLargeNumber(states.businessInfo?.checkInsToday || 0)}
        unit="confirmed customers"
      />

      {/* Spacing */}
      <Box mt={2}></Box>

      <Typography fontSize="20px" fontWeight={600}>
        Last 3 Months
      </Typography>
      <LabelValue
        loading={states.apiFetchStatus === ApiFetchStates.Loading}
        label="Views in app:"
        value={formatLargeNumber(states.last3MonthViewInApp)}
        unit="potential customers"
      />
      <LabelValue
        loading={states.apiFetchStatus === ApiFetchStates.Loading}
        label="In-store check ins:"
        value={formatLargeNumber(states.businessInfo?.checkIns3Months || 0)}
        unit="confirmed customers"
      />
    </Box>
  );
}

export default CustomerInteraction;
