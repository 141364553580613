import { RootState } from "redux-config/store";
import businessInfoActions from "../../businessInfo/actions";
import { actions } from "../slice";

const changeBusiness =
  (value: string) => (dispatch: Function, getStates: () => RootState) => {
    const states = getStates().dashboardFeature.businesses;

    dispatch(
      actions.setSelectedBusiness(
        states.businessesList.find((d) => d.id === value)!
      )
    );
    dispatch(businessInfoActions.loadBusinessInfo());
  };

export default changeBusiness;
