import { RootState } from "redux-config/store";
import { actions } from "../slice";
import ApiFetchStates from "constants/apiFetchStates";

const toggleChangePwdDia =
  () => (dispatch: Function, getStates: () => RootState) => {
    const open = getStates().authFeature.openChangePwdDia;

    dispatch(actions.setOpenChangePwdDia(!open));
    dispatch(actions.setChangePasswordFormError({}));
    dispatch(actions.setChangePasswordApiState(ApiFetchStates.Initial));
  };

export default toggleChangePwdDia;
