import { TableHead as MuiTableHead, TableRow, TableCell } from "@mui/material";

function TableHead() {
  return (
    <MuiTableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 600 }}>Date & Time</TableCell>
        <TableCell sx={{ fontWeight: 600 }}>Type</TableCell>
        <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
        <TableCell sx={{ fontWeight: 600 }}>Balance</TableCell>
        <TableCell sx={{ fontWeight: 600 }}>Id</TableCell>
      </TableRow>
    </MuiTableHead>
  );
}

export default TableHead;
