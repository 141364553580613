import { RootState } from "redux-config/store";

import { getBusinessDetail, getAnlaytic } from "services/businessesService";

import { actions } from "../slice";
import ApiFetchStates from "constants/apiFetchStates";

import {
  checkInsPerDay,
  remainingCheckInsToday,
} from "feature/dashboard/utils/checkInCal";

const loadBusinessInfo =
  (refresh: boolean = false) =>
  async (dispatch: Function, getStates: () => RootState) => {
    try {
      const { selectedBusiness } = getStates().dashboardFeature.businesses;
      const { businessInfo } = getStates().dashboardFeature.businessInfo;

      if (!selectedBusiness) return;

      if (!refresh) {
        dispatch(actions.setApiFetchStatus(ApiFetchStates.Loading));
      }

      const response = await getBusinessDetail(selectedBusiness!.id);

      if (!refresh) {
        await _fetchAnalyticData(selectedBusiness.id, dispatch);
      }

      dispatch(
        actions.setBusinessInfo({
          checkInReward: response.check_in_reward,
          dailyBudget: response.daily_budget,
          remainingDailyBalance: response.remaining_daily_balance,
          remainingBalance: response.remaining_balance,
          checkInsToday: response.check_ins_7days,
          checkIns3Months: response.check_ins_3months,
          checkInsPerDay: checkInsPerDay(
            response.check_in_reward,
            response.daily_budget
          ),
          remainingCheckInsToday: remainingCheckInsToday(
            response.check_in_reward,
            response.remaining_daily_balance
          ),

          maxReward: response.max_reward !== null ? response.max_reward : 0,
          minReward: response.min_reward !== null ? response.min_reward : 0,
          checkInCoolDown: response.check_in_cooldown,
          minCheckInCoolDown: response.min_check_in_cooldown,
          maxCheckInCoolDown: response.max_check_in_cooldown,
        })
      );

      if (!refresh) {
        dispatch(actions.setApiFetchStatus(ApiFetchStates.Done));
      }
    } catch (e) {
      if (!refresh) {
        dispatch(actions.setApiFetchStatus(ApiFetchStates.Error));
      }

      console.log(e);
    }
  };

async function _fetchAnalyticData(businessId: string, dispatch: Function) {
  const response = await getAnlaytic(businessId);

  if (!response.success) return null;

  dispatch(actions.setTodayViewInApp(response.result[0]));

  dispatch(actions.setLast3MonthViewInApp(response.result[1]));
}

export default loadBusinessInfo;
