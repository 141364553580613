import { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";

// components
import DashboardTitle from "./components/DashboardTitle";
import BusinessBalance from "./components/BusinessBalance";
import CustomerInteraction from "./components/CustomerInteraction";
import SideDetail from "./components/SideDetail";
import RetryBtnDialog from "components/molecules/Dialogs/RetryBtnDialog";

// redux
import { useAppSelector, useAppDispatch } from "redux-config/hook";
import businessInfoActions from "../redux/businessInfo/actions";
import ApiFetchStates from "constants/apiFetchStates";

function Dashboard() {
  const { businessInfo: businessInfoStates } = useAppSelector(
    (state) => state.dashboardFeature
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let refresh = 3000;
    if (!isNaN(+(process.env.REACT_APP_REFRESH_INFO || ""))) {
      refresh = +process.env.REACT_APP_REFRESH_INFO!;
    }

    const interval = setInterval(
      () => dispatch(businessInfoActions.loadBusinessInfo(true)),
      refresh
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid
        container
        direction={"row"}
        columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        px={{ xl: 3, lg: 2 }}
        pt={{ lg: 2, xs: 1 }}
      >
        <Grid item xl={1.2} lg={1.3} sm={1} xs={1}>
          <DashboardTitle />

          <Box marginTop={{ xl: 16, sm: 10, xs: 7 }}></Box>
          <BusinessBalance />
          <Box marginTop={{ xl: 16, sm: 10, xs: 7 }}></Box>
          <CustomerInteraction />
        </Grid>
        <Grid xl={0.8} lg={0.7} sm={1} xs={1} item>
          <SideDetail />
        </Grid>
      </Grid>
      <RetryBtnDialog
        content="Somthing went wrong"
        open={businessInfoStates.apiFetchStatus === ApiFetchStates.Error}
        onBtnClick={dispatch.bind(null, businessInfoActions.loadBusinessInfo())}
      />
    </>
  );
}

export default Dashboard;
