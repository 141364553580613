import { useEffect } from "react";
import { useAuth } from "@pangeacyber/react-auth";
import { Box, CircularProgress, Typography } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

function AuthScreen(props: Props) {
  const { authenticated, login, loading } = useAuth();

  useEffect(() => {
    if (!loading && !authenticated) return login();
  }, [authenticated, loading]);

  return (
    <>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100dvh"
        >
          <img src="/images/logo.png" alt="logo" width="300vw" />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ marginTop: 6 }}
          >
            <Typography>Authenticating...</Typography>
            <CircularProgress sx={{ mt: 3 }} />
          </Box>
        </Box>
      )}

      {!loading && authenticated && <>{props.children}</>}
    </>
  );
}

export default AuthScreen;
