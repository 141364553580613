import axios from "axios";

import axioInstance from "./apiConfig";

async function getUserBusinesses() {
  const response = await axioInstance.get("/businesses");
  return response.data;
}

async function getBusinessDetail(businessId: string) {
  const response = await axioInstance.get("/business/info", {
    params: { business_id: businessId },
  });

  return response.data;
}

async function editBusinessInfoDetail(
  businessId: string,
  dailyBudget: number | null,
  checkInReward: number | null,
  checkInCooldown: number | null
) {
  const editData: any = {};

  if (dailyBudget !== null) {
    editData["daily_budget"] = dailyBudget;
  }

  if (checkInReward !== null) {
    editData["check_in_reward"] = checkInReward;
  }

  if (checkInCooldown !== null) {
    editData["check_in_cooldown"] = checkInCooldown;
  }

  const response = await axioInstance.put("/business/info", {
    business_id: businessId,
    edit_fields: editData,
  });
  return response.data;
}

async function getTransactions(businessId: string) {
  const response = await axioInstance.get("/business/transactions", {
    params: {
      business_id: businessId,
    },
  });

  return response.data;
}

async function getAnlaytic(businessId: string) {
  const response = await axios.post(process.env.REACT_APP_ANALYTIC_EP || "", {
    startDates: ["7daysAgo", "30daysAgo"],
    businessId,
  });

  return response.data;
}

export {
  getUserBusinesses,
  getBusinessDetail,
  editBusinessInfoDetail,
  getTransactions,
  getAnlaytic,
};
