import { TableContainer, Table } from "@mui/material";

import scrollBar from "styles/scrollBar";

interface Props {
  children: React.ReactNode;
}

function TableLayout(props: Props) {
  return (
    <TableContainer sx={{ height: "300px", ...scrollBar }}>
      <Table sx={{ minWidth: 650 }}>{props.children}</Table>
    </TableContainer>
  );
}

export default TableLayout;
