import MediaQuery from "react-responsive";

import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";
import { DESKTOP } from "constants/breakPoints";

interface Props {
  children: React.ReactNode;
}

function SidebarLayout(props: Props) {
  return (
    <>
      <MediaQuery maxWidth={DESKTOP}>
        <Mobile>{props.children}</Mobile>
      </MediaQuery>
      <MediaQuery minWidth={DESKTOP}>
        <Desktop>{props.children}</Desktop>
      </MediaQuery>
    </>
  );
}

export default SidebarLayout;
