import { useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Transition } from "react-transition-group";

// components
import SlideMenu from "./components/SlideMenu";

interface Props {
  children: React.ReactNode;
}

function Mobile(props: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const transitionStyles: any = {
    entering: { opacity: 0, transform: "translateX(-100%)" },
    entered: { opacity: 1, transform: "translateX(0%)" },
    exiting: { opacity: 1, transform: "translateX(0%)" },
    exited: { opacity: 0, transform: "translateX(-100%)" },
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item py={1} bgcolor="bgColor.secondary">
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Button
              onClick={setOpen.bind(null, true)}
              sx={{
                color: "text.primary",
                position: "absolute",
                left: "25px",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <MenuIcon />
            </Button>
            <img src="/images/logo.png" alt="logo" width="180vw" />
          </Box>
        </Grid>

        <Grid
          mt={2}
          pb={"100px"}
          item
          sx={{
            width: "90%",
            marginX: "auto",
          }}
        >
          {props.children}
        </Grid>
      </Grid>
      <Transition in={open} timeout={600}>
        {(state: any) => (
          <SlideMenu
            transform={transitionStyles[state].transform}
            opacity={transitionStyles[state].opacity}
            onClose={setOpen.bind(null, false)}
          />
        )}
      </Transition>
    </>
  );
}

export default Mobile;
