import { combineReducers } from "@reduxjs/toolkit";

import businesses from "./businesses/slice";
import businessInfo from "./businessInfo/slice";
import transactions from "./transactions/slice";

const reducers = combineReducers({
  businesses,
  businessInfo,
  transactions,
});

export default reducers;
