import ApiFetchStates from "constants/apiFetchStates";
import { Business } from "feature/dashboard/types";

interface States {
  businessesList: Business[];
  selectedBusiness: Business | null;
  showDropDown: boolean;
  apiStatus: ApiFetchStates;
}

const initialState: States = {
  businessesList: [],
  selectedBusiness: null,
  showDropDown: false,
  apiStatus: ApiFetchStates.Loading,
};

export default initialState;
