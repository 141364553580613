import { configureStore } from "@reduxjs/toolkit";

// reducers
import authFeature from "./reducers/auth/slice";
import dashboardFeature from "feature/dashboard/redux";

const store = configureStore({
  reducer: {
    authFeature,
    dashboardFeature,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
