import { AxiosError } from "axios";
import { getUserBusinesses } from "services/businessesService";

import { actions } from "../slice";
import ApiFetchStates from "constants/apiFetchStates";
import businessInfoActions from "../../businessInfo/actions";

const loadBusinesses = () => async (dispatch: Function) => {
  try {
    dispatch(actions.setApiStatus(ApiFetchStates.Loading));
    const response = await getUserBusinesses();
    // dispatch(actions.setApiStatus(ApiFetchStates.Done));
    const businesses = response.businesses;
    dispatch(actions.setShowDropDown(businesses.length > 1));

    dispatch(
      actions.setBusinessesList(
        businesses.map((d: any) => ({
          id: d.business_id,
          name: d.business_name,
        }))
      )
    );
    if (businesses.length > 0) {
      dispatch(
        actions.setSelectedBusiness({
          id: businesses[0].business_id!,
          name: businesses[0].business_name!,
        })
      );
      dispatch(businessInfoActions.loadBusinessInfo());
    }

    dispatch(actions.setApiStatus(ApiFetchStates.Done));
  } catch (e) {
    console.log(e);
    if (e instanceof AxiosError) {
      dispatch(actions.setApiStatus(ApiFetchStates.Error));
      console.log(e);
    }
  }
};

export default loadBusinesses;
