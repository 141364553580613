import ApiFetchStates from "constants/apiFetchStates";
import type { TransactionData } from "feature/dashboard/types";

interface States {
  open: boolean;
  data: TransactionData[];
  apiFetchStatus: ApiFetchStates;
}

const initialState: States = {
  open: false,
  data: [],
  apiFetchStatus: ApiFetchStates.Loading,
};

export default initialState;
