import { Box, Typography, Skeleton } from "@mui/material";

interface Props {
  title: string;
  value: string | null;
  button?: React.ReactNode;
  loading?: boolean;
}

function Detail(props: Props) {
  return (
    <Box>
      <Typography fontSize="20px" fontWeight={600}>
        {props.title}
      </Typography>
      <Box mt={2}></Box>
      {!props.loading && <Typography>{props.value}</Typography>}
      {props.loading && (
        <Skeleton variant="rounded" width={"80px"} height="20px" />
      )}
      {props.button && <Box mt={2}></Box>}
      {props.button || <></>}
    </Box>
  );
}

export default Detail;
