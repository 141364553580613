import { RootState } from "redux-config/store";
import { actions } from "../slice";

import { editBusinessInfoDetail } from "services/businessesService";
import ApiFetchStates from "constants/apiFetchStates";

import {
  checkInsPerDay,
  remainingCheckInsToday,
} from "feature/dashboard/utils/checkInCal";
import { AxiosError } from "axios";

const editBusinessInfo =
  (
    dailyBudget: number | null = null,
    checkInReward: number | null = null,
    checkInCooldown: number | null = null
  ) =>
  async (dispatch: Function, getState: () => RootState) => {
    try {
      dispatch(actions.setEditApiStatus(ApiFetchStates.Loading));
      const { businessInfo, businesses } = getState().dashboardFeature;
      const businessId = businesses.selectedBusiness?.id;

      if (businessId === null || businessId === undefined) return;
      if (
        dailyBudget === null &&
        checkInReward === null &&
        checkInCooldown === null
      )
        return;

      await editBusinessInfoDetail(
        businessId,
        dailyBudget,
        checkInReward,
        checkInCooldown
      );

      dispatch(actions.setEditApiStatus(ApiFetchStates.Done));

      const budget =
        dailyBudget !== null
          ? dailyBudget
          : businessInfo.businessInfo!.dailyBudget;
      const reward =
        checkInReward !== null
          ? checkInReward
          : businessInfo.businessInfo!.checkInReward;

      const coolDown =
        checkInCooldown !== null
          ? checkInCooldown
          : businessInfo.businessInfo!.checkInCoolDown;

      dispatch(
        actions.setBusinessInfo({
          ...businessInfo.businessInfo!,
          dailyBudget: budget,
          checkInReward: reward,
          checkInsPerDay: checkInsPerDay(reward, budget),
          remainingCheckInsToday: remainingCheckInsToday(
            reward,
            businessInfo.businessInfo!.remainingDailyBalance
          ),
          checkInCoolDown: coolDown,
        })
      );
    } catch (e) {
      let err: string = "Something went wrong!";
      if (e instanceof AxiosError) {
        if (typeof e.response?.data.msg === "string") {
          err = e.response.data.msg;
        }
      }
      dispatch(actions.setEditErrMsg(err));

      dispatch(actions.setEditApiStatus(ApiFetchStates.Error));
      // console.log(e);
    }
  };

export default editBusinessInfo;
