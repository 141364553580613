import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { TransactionData } from "feature/dashboard/types";

import initialState from "./states";
import ApiFetchStates from "constants/apiFetchStates";

const slice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setData(state, action: PayloadAction<TransactionData[]>) {
      state.data = action.payload;
    },
    setApiFetchStatus(state, action: PayloadAction<ApiFetchStates>) {
      state.apiFetchStatus = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
