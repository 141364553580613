function formatLargeNumber(num: number) {
  const suffixes = ["", "K", "M", "B", "T"];

  let i = 0;
  while (num >= 1000) {
    num /= 1000;
    i++;
  }

  let result: string | number = num;

  if (suffixes[i].trim().length !== 0) {
    result = num.toFixed(1) + suffixes[i];
  }

  return result;
}

export default formatLargeNumber;
