import {
  Dialog,
  Paper,
  Typography,
  Box,
  IconButton,
  TextField,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "components/atoms/Button/PrimaryButton";

interface Props {
  title: string;
  label: string;
  value: string;
  open: boolean;
  loading: boolean;
  btnText: string;
  errorText: string | null;
  onChange(val: string): void;
  onClose(): void;
  onSubmit(): void;
}

function TextInputDialog(props: Props) {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          borderRadius: "6px",
        },
      }}
    >
      <Paper
        sx={{
          paddingX: "18px",
          paddingY: "12px",
          minWidth: { lg: 300, xs: 200 },

          borderRadius: "6px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} fontSize="18px">
            {props.title}
          </Typography>
          <IconButton disabled={props.loading} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={1}>
          <InputLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
            {props.label}
          </InputLabel>
          <TextField
            sx={{ mt: 1 }}
            error={props.value.trim().length === 0 || props.errorText !== null}
            value={props.value}
            size="small"
            fullWidth
            disabled={props.loading}
            onChange={(e) => props.onChange(e.target.value)}
          />
          <Box sx={{ maxWidth: "230px" }}>
            <Typography fontSize="10px" fontWeight={600} sx={{ color: "red" }}>
              {props.errorText}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          {!props.loading && (
            <PrimaryButton
              text={props.btnText}
              btnColor="primary.main"
              onClick={props.onSubmit}
            />
          )}
          {props.loading && (
            <Box>
              <CircularProgress size="small" />
            </Box>
          )}
        </Box>
      </Paper>
    </Dialog>
  );
}

export default TextInputDialog;
