import { Typography, Dialog, Paper, Box, Link } from "@mui/material";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";

import LineIcon from "svg/LineIcon";

interface Props {
  content: string;
  open: boolean;
}

function ContactDialog(props: Props) {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          borderRadius: "6px",
        },
      }}
    >
      <Paper
        sx={{
          paddingY: "40px",
          paddingX: "40px",
          minWidth: { lg: 500, xs: 200 },

          borderRadius: "6px",
        }}
      >
        <Typography fontSize={"24px"} mt={1}>
          {props.content}
        </Typography>

        <Box
          display="flex"
          columnGap={2}
          sx={{
            mt: 6,
          }}
        >
          <MarkunreadOutlinedIcon />
          <Link
            href="mailto:air@developpa.io"
            fontWeight={600}
            variant="body2"
            fontSize={"18px"}
            sx={{
              ".MuiLink-root": {
                color: "red",
              },
            }}
          >
            air@developpa.io
          </Link>
        </Box>
        <Box
          display="flex"
          columnGap={2}
          sx={{
            mt: 2,
          }}
        >
          <LineIcon height="20px" width="20px" />
          <Link
            href="https://page.line.me/airdeveloppa"
            fontWeight={600}
            variant="body2"
            fontSize={"18px"}
            sx={{
              ".MuiLink-root": {
                color: "red",
              },
            }}
          >
            Line
          </Link>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default ContactDialog;
