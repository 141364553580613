import { useEffect } from "react";
import {
  Dialog,
  Box,
  Paper,
  IconButton,
  Typography,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// redux
import { useAppDispatch, useAppSelector } from "redux-config/hook";
import transactionActions from "feature/dashboard/redux/transactions/actions";

// components
import TableHead from "./components/TableHead";
import TableLayout from "./components/TableLayout";
import TableData from "./components/TableData";
import LoadingSkeleton from "./components/LoadingSkeleton";
import Error from "./components/Error";
import ApiFetchStates from "constants/apiFetchStates";

function TransactionHistoryDialog() {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.dashboardFeature.transactions);

  useEffect(() => {
    if (states.open) dispatch(transactionActions.loadTransactions());
  }, [states.open]);

  return (
    <Dialog
      open={states.open}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "6px",
        },
      }}
    >
      <Paper
        sx={{
          paddingX: "18px",
          paddingY: "12px",
          borderRadius: "6px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} fontSize="18px">
            Transaction History
          </Typography>
          <IconButton
            onClick={() => dispatch(transactionActions.toggleDialog())}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={1}>
          {states.apiFetchStatus === ApiFetchStates.Error && (
            <Error
              onRetry={() => dispatch(transactionActions.loadTransactions())}
            />
          )}
          {states.apiFetchStatus !== ApiFetchStates.Error && (
            <TableLayout>
              <TableHead />
              {states.apiFetchStatus === ApiFetchStates.Loading && (
                <LoadingSkeleton />
              )}
              <TableBody>
                {states.apiFetchStatus === ApiFetchStates.Done && (
                  <>
                    {states.data.map((d) => (
                      <TableData key={d.id} data={d} />
                    ))}
                  </>
                )}
              </TableBody>
            </TableLayout>
          )}
        </Box>
      </Paper>
    </Dialog>
  );
}

export default TransactionHistoryDialog;
