import { useEffect, useState } from "react";
import {
  Dialog,
  Paper,
  Typography,
  Box,
  IconButton,
  TextField,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth, getTokenFromCookie } from "@pangeacyber/react-auth";

import PrimaryButton from "components/atoms/Button/PrimaryButton";

import { useAppSelector, useAppDispatch } from "redux-config/hook";
import authActions from "redux-config/reducers/auth/actions";
import ApiFetchStates from "constants/apiFetchStates";
import SnakBar from "components/atoms/SnackBar/SnackBar";

function PasswordChange() {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.authFeature);

  const { getToken, user } = useAuth();

  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [oldPwd, setOldPwd] = useState<string>("");
  const [newPwd, setNewPwd] = useState<string>("");
  const [confirmNewPwd, setConfirmNewPwd] = useState<string>("");

  const handlePwdChange = () => {
    const token = getToken();
    dispatch(
      authActions.changePassword(token || "", newPwd, confirmNewPwd, oldPwd)
    );
  };

  useEffect(() => {
    if (states.changePwdApiState === ApiFetchStates.Error) {
      setOpenSnackBar(true);
    }
  }, [states.changePwdApiState]);

  return (
    <>
      <Dialog
        open={states.openChangePwdDia}
        PaperProps={{
          sx: {
            borderRadius: "6px",
          },
        }}
      >
        <Paper
          sx={{
            paddingX: "18px",
            paddingY: "12px",
            minWidth: { lg: 300, xs: 200 },
            borderRadius: "6px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600} fontSize="18px">
              Change Password
            </Typography>
            <IconButton
              onClick={dispatch.bind(null, authActions.toggleChangePwdDia())}
              disabled={states.changePwdApiState === ApiFetchStates.Loading}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box mt={1}>
            <InputLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
              Old Password
            </InputLabel>
            <TextField
              error={"oldPassword" in states.changePwdFormError}
              disabled={states.changePwdApiState === ApiFetchStates.Loading}
              sx={{ mt: 1 }}
              size="small"
              fullWidth
              onChange={(e) => setOldPwd(e.target.value)}
            />

            <Typography fontSize="10px" fontWeight={600} sx={{ color: "red" }}>
              {states.changePwdFormError.oldPassword || ""}
            </Typography>
          </Box>
          <Box mt={1}>
            <InputLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
              New Password
            </InputLabel>
            <TextField
              error={"newPassword" in states.changePwdFormError}
              disabled={states.changePwdApiState === ApiFetchStates.Loading}
              sx={{ mt: 1 }}
              size="small"
              fullWidth
              onChange={(e) => setNewPwd(e.target.value)}
            />
            <Typography fontSize="10px" fontWeight={600} sx={{ color: "red" }}>
              {states.changePwdFormError.newPassword || ""}
            </Typography>
          </Box>
          <Box mt={1}>
            <InputLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
              Confirm New Password
            </InputLabel>
            <TextField
              error={"confirmNewPassword" in states.changePwdFormError}
              disabled={states.changePwdApiState === ApiFetchStates.Loading}
              sx={{ mt: 1 }}
              size="small"
              fullWidth
              onChange={(e) => setConfirmNewPwd(e.target.value)}
            />
            <Typography fontSize="10px" fontWeight={600} sx={{ color: "red" }}>
              {states.changePwdFormError.confirmNewPassword || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            {states.changePwdApiState !== ApiFetchStates.Loading && (
              <PrimaryButton
                text={"Change"}
                btnColor="primary.main"
                onClick={handlePwdChange}
              />
            )}
          </Box>
        </Paper>
      </Dialog>
      <SnakBar
        message={states.changePwdErrorMsg}
        onClose={() => setOpenSnackBar(false)}
        open={openSnackBar}
      />
    </>
  );
}

export default PasswordChange;
