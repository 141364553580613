import { Typography, Dialog, Paper, Box, Button } from "@mui/material";

interface Props {
  content: string;
  open: boolean;
  onBtnClick(): void;
}

function RetryBtnDialog(props: Props) {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          borderRadius: "6px",
        },
      }}
    >
      <Paper
        sx={{
          paddingY: "12px",
          paddingX: "20px",
          minWidth: { lg: 400, xs: 300 },

          borderRadius: "6px",
        }}
      >
        <Typography fontSize={"23px"} fontWeight={600}>
          Error
        </Typography>

        <Typography fontSize={"16px"} mt={1}>
          {props.content}
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          sx={{
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={props.onBtnClick}
          >
            Retry
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default RetryBtnDialog;
