// ui
import SidebarLayout from "../layouts/SidebarLayout";
import DashboardFeature from "feature/dashboard/ui";
import AuthScreen from "auth/AuthScreen";
import PasswordChange from "feature/passwordChange/ui";

function Dashboard() {
  return (
    <>
      <AuthScreen>
        <SidebarLayout>
          <DashboardFeature />
        </SidebarLayout>
      </AuthScreen>
      <PasswordChange />
    </>
  );
}

export default Dashboard;
