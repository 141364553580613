export const redirectWebApp = () =>
  window.open("https://app.airdeveloppa.co", "_blank");

export const redirectHomePage = () =>
  window.open("https://airdeveloppa.co", "_blank");

export const redirectContact = () => window.open("mailto:air@developpa.io");

export const redirectLine = () =>
  window.open("https://page.line.me/airdeveloppa");

export const redirectBuyAirPurifier = () =>
  window.open("https://airdeveloppa.co/aircleaner-personal");
