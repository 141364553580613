const scrollBar = {
  "&::-webkit-scrollbar": {
    width: ".30rem",
    height: ".30rem",
    borderRadius: ".25rem",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#57CAF3",
    borderRadius: ".25rem",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#57CAF3",
  },
};

export default scrollBar;
