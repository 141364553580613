import { useState } from "react";
import {
  Dialog,
  Paper,
  Typography,
  Box,
  IconButton,
  TextField,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "components/atoms/Button/PrimaryButton";

interface Props {
  open: boolean;
  businessId: string;
  onClose(): void;
}

function TopupStripeDialog(props: Props) {
  const [amount, setAmount] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (isNaN(+amount) || +amount <= 0) return e.preventDefault();
    if (+amount < 300) {
      setErrorMsg("Minimum top-up amount is THB 300");
      return e.preventDefault();
    }
  };

  const onChange = (val: string) => {
    if (val.trim().length === 0) return setAmount(val);

    if (isNaN(+val)) return;

    setAmount(val);
  };

  const handleClose = () => {
    setErrorMsg(null);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          borderRadius: "6px",
        },
      }}
    >
      <Paper
        sx={{
          paddingX: "18px",
          paddingY: "12px",
          minWidth: { lg: 300, xs: 200 },
          borderRadius: "6px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} fontSize="18px">
            Top up
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form
          onSubmit={handleFormSubmit}
          action={process.env.REACT_APP_BE_URL! + "/checkout/start"}
          method="POST"
        >
          <input hidden name="business_id" value={props.businessId} />
          <Box mt={1}>
            <InputLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
              Amount (in THB)
            </InputLabel>
            <TextField
              error={errorMsg !== null}
              sx={{ mt: 1 }}
              name="amount"
              size="small"
              fullWidth
              value={amount}
              onChange={(e) => onChange(e.target.value)}
            />
            <Typography fontSize="10px" fontWeight={600} sx={{ color: "red" }}>
              {errorMsg || ""}
            </Typography>
            <Box sx={{ maxWidth: "230px" }}>
              <Typography
                fontSize="10px"
                fontWeight={600}
                sx={{ color: "red" }}
              ></Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <PrimaryButton
              text={"Submit"}
              btnColor="primary.main"
              type="submit"
            />
          </Box>
        </form>
      </Paper>
    </Dialog>
  );
}

export default TopupStripeDialog;
