import { useAuth } from "@pangeacyber/react-auth";
import { Box, Divider } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PublicIcon from "@mui/icons-material/Public";
import FilterVintageOutlinedIcon from "@mui/icons-material/FilterVintageOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";

// redux
import { useAppDispatch } from "redux-config/hook";
import authActions from "redux-config/reducers/auth/actions";

// components
import LineIcon from "svg/LineIcon";
import IconButton from "../../components/IconButton";

// util
import * as redirect from "../../../util/redirect";

function SideMenu() {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  return (
    <>
      <Box width="300px" height="100%">
        <Box height="100%" display="flex" columnGap={2}>
          <Box height="100%" sx={{ paddingX: "30px" }}>
            <Box sx={{ height: "70%" }} pt={2}>
              <img src="/images/logo.png" alt="logo" width="220vw" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-end"
              sx={{ height: "30%" }}
              pb={6}
              rowGap={0.1}
            >
              <IconButton
                icon={<PowerSettingsNewIcon sx={{ fontSize: "16px" }} />}
                text="Logout"
                onClick={logout}
              />
              <IconButton
                icon={<LockResetIcon sx={{ fontSize: "16px" }} />}
                text="Change Password"
                onClick={dispatch.bind(null, authActions.toggleChangePwdDia())}
              />
              <IconButton
                icon={<PublicIcon sx={{ fontSize: "16px" }} />}
                text="Webapp"
                onClick={redirect.redirectWebApp.bind(null)}
              />
              <IconButton
                icon={<FilterVintageOutlinedIcon sx={{ fontSize: "16px" }} />}
                text="AirDeveloppa Homepage"
                onClick={redirect.redirectHomePage.bind(null)}
              />

              <IconButton
                icon={<MarkunreadOutlinedIcon sx={{ fontSize: "16px" }} />}
                text="air@developpa.io"
                onClick={redirect.redirectContact.bind(null)}
              />

              <IconButton
                icon={<LineIcon />}
                text="LINE"
                onClick={redirect.redirectLine.bind(null)}
              />
              <IconButton
                icon={<LocalMallOutlinedIcon sx={{ fontSize: "16px" }} />}
                text="Buy air purifier"
                onClick={redirect.redirectBuyAirPurifier.bind(null)}
              />
            </Box>
          </Box>
          <Box py={3}>
            <Divider orientation="vertical" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SideMenu;
