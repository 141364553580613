import { Button, SxProps, Theme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
  text: string;
  type?: "submit" | "reset" | "button";
  btnColor: string;
  disabled?: boolean;
  onClick?: () => void;
}

function PrimaryButton(props: Props) {
  return (
    <Button
      disabled={props.disabled}
      type={props.type}
      sx={{
        color: "white",
        backgroundColor: props.btnColor,
        textDecoration: "underline",
        borderRadius: "20px",
        textTransform: "none",
        px: "13px",
        fontSize: "15px",
        "&.Mui-disabled": {
          opacity: 0.5,
        },
        "&:hover": {
          backgroundColor: props.btnColor,
          textDecoration: "underline",
        },
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
}

export default PrimaryButton;
