import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import initialState from "./states";
import { Business } from "feature/dashboard/types";
import ApiFetchStates from "constants/apiFetchStates";

const slice = createSlice({
  name: "businesses",
  initialState,
  reducers: {
    setBusinessesList(state, action: PayloadAction<Business[]>) {
      state.businessesList = action.payload;
    },
    setSelectedBusiness(state, action: PayloadAction<Business | null>) {
      state.selectedBusiness = action.payload;
    },
    setShowDropDown(state, action: PayloadAction<boolean>) {
      state.showDropDown = action.payload;
    },
    setApiStatus(state, action: PayloadAction<ApiFetchStates>) {
      state.apiStatus = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
