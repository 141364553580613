import { useEffect } from "react";
import { Typography, Box, Skeleton, Select, MenuItem } from "@mui/material";

// hooks
import { useAppSelector, useAppDispatch } from "redux-config/hook";

// constants
import ApiFetchStates from "constants/apiFetchStates";

// components
import RetryBtnDialog from "components/molecules/Dialogs/RetryBtnDialog";
import ContactDialog from "components/molecules/Dialogs/ContactDialog";

// actions
import businessesActions from "feature/dashboard/redux/businesses/actions";

function DashboardTitle() {
  const states = useAppSelector((states) => states.dashboardFeature.businesses);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(businessesActions.loadBusinesses());
  }, []);

  return (
    <>
      <Box>
        <Typography fontSize={{ xl: "55px", xs: "30px" }} fontWeight={700}>
          Business Dashboard
        </Typography>
        {states.apiStatus === ApiFetchStates.Done && states.showDropDown && (
          <Select
            autoWidth
            value={states.selectedBusiness!.id}
            onChange={(e) =>
              dispatch(businessesActions.changeBusiness(e.target.value))
            }
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              fontSize: "24px",
            }}
          >
            {states.businessesList.map((d) => (
              <MenuItem key={d.id} value={d.id}>
                {d.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {states.apiStatus === ApiFetchStates.Loading && (
          <Skeleton
            variant="rectangular"
            width={300}
            height={40}
            sx={{ borderRadius: 2 }}
          />
        )}
        {states.apiStatus === ApiFetchStates.Done && !states.showDropDown && (
          <Typography fontSize="24px">
            {states.selectedBusiness?.name}
          </Typography>
        )}
      </Box>
      <RetryBtnDialog
        content="Somthing went wrong"
        open={states.apiStatus === ApiFetchStates.Error}
        onBtnClick={dispatch.bind(null, businessesActions.loadBusinesses())}
      />

      <ContactDialog
        open={
          states.apiStatus === ApiFetchStates.Done &&
          states.businessesList.length === 0
        }
        content="Your account has no link to business. Please contanct us to resolve this issue."
      />
    </>
  );
}

export default DashboardTitle;
