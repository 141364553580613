import ApiFetchStates from "constants/apiFetchStates";

import type { BusinessInfo } from "feature/dashboard/types";

interface States {
  businessInfo: BusinessInfo | null;
  apiFetchStatus: ApiFetchStates;
  editApiStatus: ApiFetchStates;
  editErrorMsg: string;
  todayViewInApp: number;
  last3MonthViewInApp: number;
}

const initialState: States = {
  businessInfo: null,
  apiFetchStatus: ApiFetchStates.Loading,
  editApiStatus: ApiFetchStates.Initial,
  editErrorMsg: "",
  todayViewInApp: 0,
  last3MonthViewInApp: 0,
};

export default initialState;
