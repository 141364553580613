import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import ThemedApp from "./ThemedApp";
import ReduxProvider from "redux-config/Provider";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

root.render(
  <ThemedApp>
    <ReduxProvider>
      <RouterProvider router={router} />
    </ReduxProvider>
  </ThemedApp>
);
