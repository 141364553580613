import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { BusinessInfo } from "feature/dashboard/types";

import initialState from "./states";
import ApiFetchStates from "constants/apiFetchStates";

const slice = createSlice({
  name: "businessInfo",
  initialState,
  reducers: {
    setBusinessInfo(state, action: PayloadAction<BusinessInfo | null>) {
      state.businessInfo = action.payload;
    },
    setApiFetchStatus(state, action: PayloadAction<ApiFetchStates>) {
      state.apiFetchStatus = action.payload;
    },
    setEditApiStatus(state, action: PayloadAction<ApiFetchStates>) {
      state.editApiStatus = action.payload;
    },
    setEditErrMsg(state, action: PayloadAction<string>) {
      state.editErrorMsg = action.payload;
    },
    setTodayViewInApp(state, action: PayloadAction<number>) {
      state.todayViewInApp = action.payload;
    },
    setLast3MonthViewInApp(state, action: PayloadAction<number>) {
      state.last3MonthViewInApp = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
