import { Box, Snackbar, Alert } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  message: string;
  open: boolean;
  onClose(): void;
}

function SnakBar(props: Props) {
  return (
    <Snackbar
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={props.open}
      onClose={props.onClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        sx={{ background: "white" }}
        icon={
          <ErrorIcon
            sx={{
              color: "red",
            }}
          />
        }
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default SnakBar;
