import { useNavigate } from "react-router-dom";
import { AuthProvider, AppState } from "@pangeacyber/react-auth";

import Dashboard from "./pages/Dashboard";

function App() {
  const navigate = useNavigate();

  const handleLogin = (appData: AppState) => {
    const query = new URLSearchParams(window.location.search);
    const success = query.get("success");
    let param = "";
    if (success !== null) param = `success=${success}`;

    navigate(appData.returnPath + param);
  };

  return (
    <AuthProvider
      config={{
        domain: process.env.REACT_APP_PANGEA_DOMAIN!,
        clientToken: process.env.REACT_APP_CLIENT_TOKEN!,
      }}
      cookieOptions={{
        useCookie: true,
        cookieName: process.env.REACT_APP_COOKIE_NAME!,
        cookieDomain: process.env.REACT_APP_BE_DOMAIN!,
      }}
      onLogin={handleLogin}
      loginUrl={process.env.REACT_APP_LOGIN_URL!}
      useStrictStateCheck={false}
    >
      <Dashboard />
    </AuthProvider>
  );
}

export default App;
