import { Box } from "@mui/material";

//styles
import scrollBar from "styles/scrollBar";

// components
import SideMenu from "./components/SideMenu";

interface Props {
  children: React.ReactNode;
}

function Desktop(props: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      sx={{
        backgroundImage:
          "linear-gradient(to bottom, #00b7fc, #00beeb, #00c1d0, #00c2af, #50c08d)",
      }}
    >
      <Box
        display="flex"
        height={{ xl: "93vh", lg: "97vh" }}
        width={{ xl: "93vw", lg: "97vw" }}
        sx={{ background: "#f4f4f4", borderRadius: "12px" }}
      >
        <SideMenu />
        <Box
          flexGrow={1}
          height="100%"
          sx={{ ...scrollBar, overflowY: "scroll" }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

export default Desktop;
