import { useAuth } from "@pangeacyber/react-auth";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PublicIcon from "@mui/icons-material/Public";
import FilterVintageOutlinedIcon from "@mui/icons-material/FilterVintageOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";

// redux
import { useAppDispatch } from "redux-config/hook";
import authActions from "redux-config/reducers/auth/actions";

// components
import LineIcon from "svg/LineIcon";
import IconButton from "../../components/IconButton";

// util
import * as redirect from "../../../util/redirect";

interface Props {
  transform: string;
  opacity: number;
  onClose: () => void;
}

function SlideMenu(props: Props) {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0px",
        height: "100vh",
        width: { xs: "100vw", sm: "85vw", md: "40vw", lg: "30vw" },
        backgroundColor: "bgColor.secondary",
        pl: 2,
        transform: props.transform,
        opacity: props.opacity,
        transition: "all 500ms ease-in-out",
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ height: "20vh" }}
      >
        <Box mt={2}>
          <img src="/images/logo.png" alt="logo" width="230vw" />
        </Box>
        <Button sx={{ color: "text.primary" }} onClick={props.onClose}>
          <CloseIcon />
        </Button>
      </Box>
      <Box sx={{ height: "80vh" }} rowGap={1}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ height: "350px" }}
        >
          <IconButton
            icon={<PowerSettingsNewIcon sx={{ fontSize: "20px" }} />}
            text="Logout"
            onClick={logout}
          />
          <IconButton
            icon={<LockResetIcon sx={{ fontSize: "20px" }} />}
            text="Change Password"
            onClick={dispatch.bind(null, authActions.toggleChangePwdDia())}
          />
          <IconButton
            icon={<PublicIcon sx={{ fontSize: "20px" }} />}
            text="Webapp"
            onClick={redirect.redirectWebApp.bind(null)}
          />
          <IconButton
            icon={<FilterVintageOutlinedIcon sx={{ fontSize: "20px" }} />}
            text="AirDeveloppa Homepage"
            onClick={redirect.redirectHomePage.bind(null)}
          />
          <IconButton
            icon={<MarkunreadOutlinedIcon sx={{ fontSize: "20px" }} />}
            text="air@developpa.io"
            onClick={redirect.redirectContact.bind(null)}
          />
          <IconButton
            icon={<LineIcon height="19px" width="20px" />}
            text="LINE"
            onClick={redirect.redirectLine.bind(null)}
          />
          <IconButton
            icon={<LocalMallOutlinedIcon sx={{ fontSize: "20px" }} />}
            text="Buy air purifier"
            onClick={redirect.redirectBuyAirPurifier.bind(null)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SlideMenu;
