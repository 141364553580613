import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;
}

function ThemedApp(props: Props) {
  const theme = createTheme({
    typography: {
      fontFamily: ["Century Gothic", "sans-serif"].join(","),
    },
    palette: {
      mode: "light",
      ...{
        primary: {
          main: "#80D0A9",
        },
        secondary: {
          main: "#57CAF3",
        },

        text: {
          primary: "#282828",
        },

        success: {
          main: "#00CC00",
        },
        error: {
          main: "#FF0000",
        },
        bgColor: {
          main: "#F4F4F4",
          secondary: "white",
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default ThemedApp;
