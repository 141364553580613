import { TableCell, TableRow, Skeleton } from "@mui/material";

function LoadingSkeleton() {
  return (
    <>
      {Array(5)
        .fill(0)
        .map((_, idx) => (
          <TableRow hover key={idx + "_key"}>
            <TableCell colSpan={5} sx={{ border: "none", p: 1 }}>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}

export default LoadingSkeleton;
