import { useState, useEffect } from "react";
import { Box } from "@mui/material";

// redux
import { useAppSelector, useAppDispatch } from "redux-config/hook";
import businessInfoActions from "../../../redux/businessInfo/actions";

// components
import PrimaryButton from "components/atoms/Button/PrimaryButton";
import Detail from "./components/Detail";
import ApiFetchStates from "constants/apiFetchStates";
import SnackBar from "components/atoms/SnackBar/SnackBar";
import TextInputDialog from "components/molecules/Dialogs/TextInputDialog";

function SideDetail() {
  // update types
  enum UpdateType {
    Budget = "budget",
    checkInReward = "checkInReward",
    checkInCooldown = "checkInCooldown",
  }

  const states = useAppSelector((state) => state.dashboardFeature.businessInfo);
  const dispatch = useAppDispatch();

  const [openEditDia, setOpenEditDia] = useState<boolean>(false);
  const [labelName, setLabelName] = useState<string>("");
  const [editValue, setEditValue] = useState<string>("");
  const [updateType, setUpdateType] = useState<UpdateType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const toggleDialog = () => {
    if (openEditDia) setErrorMessage(null);
    setOpenEditDia((prev) => !prev);
  };

  const onChange = (val: string) => {
    if (val.trim().length === 0) return setEditValue(val);

    if (isNaN(+val)) return;

    setEditValue(val);

    if (val.trim().length === 0) return;

    // budget update check
    if (updateType === UpdateType.Budget) {
      if (+val < states.businessInfo!.checkInReward!)
        return setErrorMessage(
          "Daily budget amount cannot be less than Check in reward."
        );
    }

    // daily budget update check
    if (updateType === UpdateType.checkInReward) {
      if (+val > states.businessInfo!.dailyBudget) {
        return setErrorMessage(
          "Check in reward amount cannot be greater than Daily budget."
        );
      }

      if (+val < states.businessInfo!.minReward)
        return setErrorMessage(
          `Minimum reward cannot be less than THB ${
            states.businessInfo!.minReward
          }`
        );

      if (+val > states.businessInfo!.maxReward)
        return setErrorMessage(
          `Maximum reward cannot be grater than THB ${
            states.businessInfo!.maxReward
          }`
        );
    }

    // check in cooldown check
    if (updateType === UpdateType.checkInCooldown) {
      if (+val > states.businessInfo!.maxCheckInCoolDown)
        return setErrorMessage(
          `Maximum check in cool down days cannot be greater than ${
            states.businessInfo!.maxCheckInCoolDown
          }`
        );
      if (+val < states.businessInfo!.minCheckInCoolDown)
        return setErrorMessage(
          `Maximum check in cool down days cannot be less than ${
            states.businessInfo!.minCheckInCoolDown
          }`
        );
    }

    setErrorMessage(null);
  };

  const onSubmit = () => {
    if (editValue.trim().length === 0 || errorMessage !== null) return;

    dispatch(
      businessInfoActions.editBusinessInfo(
        updateType === UpdateType.Budget ? +editValue : null,
        updateType === UpdateType.checkInReward ? +editValue : null,
        updateType === UpdateType.checkInCooldown ? +editValue : null
      )
    );
  };

  useEffect(() => {
    if (states.editApiStatus === ApiFetchStates.Error) setOpenSnackBar(true);
    if (states.editApiStatus === ApiFetchStates.Done) setOpenEditDia(false);
  }, [states.editApiStatus]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "bgColor.secondary",
          borderRadius: "12px",
          paddingY: 5,
          paddingX: 2,
          mt: { xs: 5 },
          width: { xl: "300px", lg: "90%", md: "90%", sm: "350px" },
          marginX: { lg: "auto", xs: "0px" },
        }}
      >
        <Detail
          title="Daily budget"
          value={`฿ ${states.businessInfo?.dailyBudget}`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
          button={
            <PrimaryButton
              disabled={states.apiFetchStatus === ApiFetchStates.Loading}
              btnColor="secondary.main"
              text="Edit"
              onClick={() => {
                setLabelName("Daily Budget");
                setEditValue(states.businessInfo?.dailyBudget + "");
                setUpdateType(UpdateType.Budget);
                toggleDialog();
              }}
            />
          }
        />
        <Box marginTop={5}></Box>
        <Detail
          title="Check in reward"
          value={`฿ ${states.businessInfo?.checkInReward}`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
          button={
            <PrimaryButton
              disabled={states.apiFetchStatus === ApiFetchStates.Loading}
              btnColor="secondary.main"
              text="Edit"
              onClick={() => {
                setLabelName("Check in reward");
                setEditValue(states.businessInfo?.checkInReward + "");
                setUpdateType(UpdateType.checkInReward);
                toggleDialog();
              }}
            />
          }
        />
        <Box marginTop={5}></Box>
        <Detail
          title="Check in cooldown"
          value={`${states.businessInfo?.checkInCoolDown} Day(s)`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
          button={
            <PrimaryButton
              disabled={states.apiFetchStatus === ApiFetchStates.Loading}
              btnColor="secondary.main"
              text="Edit"
              onClick={() => {
                setLabelName("Check in cooldown (Days)");
                setEditValue(states.businessInfo?.checkInCoolDown + "");
                setUpdateType(UpdateType.checkInCooldown);
                toggleDialog();
              }}
            />
          }
        />
        <Box marginTop={5}></Box>
        <Detail
          title="Remaining daily balance"
          value={`฿ ${states.businessInfo?.remainingDailyBalance}`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
        />
        <Box marginTop={5}></Box>
        <Detail
          title="Check ins per day"
          value={`${states.businessInfo?.checkInsPerDay} check ins`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
        />
        <Box marginTop={5}></Box>
        <Detail
          title="Remaining check ins today"
          value={`${states.businessInfo?.remainingCheckInsToday} check ins`}
          loading={states.apiFetchStatus === ApiFetchStates.Loading}
        />
      </Box>
      <TextInputDialog
        open={openEditDia}
        title="Edit"
        label={labelName}
        value={editValue}
        loading={states.editApiStatus === ApiFetchStates.Loading}
        onClose={toggleDialog}
        errorText={errorMessage}
        btnText="Ok"
        onChange={onChange}
        onSubmit={onSubmit}
      />

      <SnackBar
        message={states.editErrorMsg}
        onClose={() => setOpenSnackBar(false)}
        open={openSnackBar}
      />
    </>
  );
}

export default SideDetail;
