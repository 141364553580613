import ApiFetchStates from "constants/apiFetchStates";
import { actions } from "../slice";
import changePasswordService from "services/authServices";
import { AxiosError } from "axios";

const changePassword =
  (token: string, newPwd: string, confirmPwd: string, oldPwd: string) =>
  async (dispatch: Function) => {
    const error: { [key: string]: string } = {};

    if (oldPwd.trim().length === 0) {
      error["oldPassword"] = "Old password require.";
    }

    if (newPwd.trim().length === 0) {
      error["newPassword"] = "New Password require.";
    } else {
      if (newPwd !== confirmPwd) {
        error["confirmNewPassword"] = "Password not match.";
      }
    }

    dispatch(actions.setChangePasswordFormError(error));

    if (Object.keys(error).length !== 0) return;

    dispatch(actions.setChangePasswordApiState(ApiFetchStates.Loading));

    try {
      await changePasswordService(token, newPwd, oldPwd);
      dispatch(actions.setChangePasswordApiState(ApiFetchStates.Done));
      dispatch(actions.setOpenChangePwdDia(false));
    } catch (e) {
      let msg = "Something went wrong!";
      dispatch(actions.setChangePasswordApiState(ApiFetchStates.Error));
      if (e instanceof AxiosError) {
        if (typeof e.response?.data.msg === "string") {
          msg = e.response.data.msg;
        }
      }

      dispatch(actions.setChangePasswordErrorMsg(msg));
    }
  };

export default changePassword;
