import { Grid, Typography, Button, Box } from "@mui/material";

interface Props {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

function IconButton(props: Props) {
  return (
    <Button
      sx={{ color: "text.primary", textTransform: "none" }}
      onClick={props.onClick}
    >
      {props.icon}
      <Typography
        ml={1}
        fontSize={{ lg: "13px", sm: "14px" }}
        textTransform="none"
      >
        {props.text}
      </Typography>
    </Button>
  );
}

export default IconButton;
