import { TableCell, TableRow } from "@mui/material";
import type { TransactionData } from "feature/dashboard/types";

interface Props {
  data: TransactionData;
}

function TableData(props: Props) {
  return (
    <TableRow>
      <TableCell>{props.data.timeStamp}</TableCell>
      <TableCell>{props.data.type}</TableCell>
      <TableCell>{props.data.amount}</TableCell>
      <TableCell>{props.data.balance}</TableCell>
      <TableCell>{props.data.id}</TableCell>
    </TableRow>
  );
}

export default TableData;
