import ApiFetchStates from "constants/apiFetchStates";
import AuthStates from "constants/authStates";

interface States {
  openChangePwdDia: boolean;
  changePwdApiState: ApiFetchStates;
  newPwd: string;
  confirmNewPwd: string;
  oldPwd: string;
  changePwdFormError: { [key: string]: string };
  changePwdErrorMsg: string;
}

const initialState: States = {
  openChangePwdDia: false,
  changePwdApiState: ApiFetchStates.Initial,
  newPwd: "",
  confirmNewPwd: "",
  oldPwd: "",
  changePwdFormError: {},
  changePwdErrorMsg: "",
};

export default initialState;
