function checkInsPerDay(checkInReward: number, dailyBudget: number): number {
  if (checkInReward === 0) return 0;

  return Math.floor(dailyBudget / checkInReward);
}

function remainingCheckInsToday(
  checkInReward: number,
  remainingDailyBudget: number
) {
  if (checkInReward === 0) return 0;
  return Math.floor(remainingDailyBudget / checkInReward);
}

export { checkInsPerDay, remainingCheckInsToday };
