import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import initialState from "./states";

import ApiFetchStates from "constants/apiFetchStates";

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOpenChangePwdDia(state, action: PayloadAction<boolean>) {
      state.openChangePwdDia = action.payload;
    },
    setChangePasswordApiState(state, action: PayloadAction<ApiFetchStates>) {
      state.changePwdApiState = action.payload;
    },
    setNewPassword(state, action: PayloadAction<string>) {
      state.newPwd = action.payload;
    },
    setOldPassword(state, action: PayloadAction<string>) {
      state.oldPwd = action.payload;
    },
    setConfirmNewPassword(state, action: PayloadAction<string>) {
      state.confirmNewPwd = action.payload;
    },
    setChangePasswordFormError(
      state,
      action: PayloadAction<{ [key: string]: string }>
    ) {
      state.changePwdFormError = action.payload;
    },
    setChangePasswordErrorMsg(state, action: PayloadAction<string>) {
      state.changePwdErrorMsg = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
