import { Typography, Box, Skeleton } from "@mui/material";

interface Props {
  label: string;
  value: number | string;
  unit: string;
  loading?: boolean;
}

function LabelValue(props: Props) {
  return (
    <Box display="flex" justifyContent="space-between" width={"450px"}>
      <Typography fontSize="15px">{props.label}</Typography>

      {!props.loading && (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ width: "70%" }}
          columnGap={"12px"}
        >
          <Typography width={"100px"} fontSize="15px" textAlign="right">
            {props.value}
          </Typography>
          <Box sx={{ width: "200px" }}>
            <Typography textAlign="left" fontSize="15px">
              {props.unit}
            </Typography>
          </Box>
        </Box>
      )}
      {props.loading && (
        <Skeleton variant="rounded" width={"80px"} height="20px" />
      )}
    </Box>
  );
}

export default LabelValue;
