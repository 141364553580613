import { RootState } from "redux-config/store";
import { actions } from "../slice";

const toggleDialog =
  () => async (dispatch: Function, getStates: () => RootState) => {
    const open = getStates().dashboardFeature.transactions.open;
    dispatch(actions.setOpen(!open));
  };

export default toggleDialog;
