import moment from "moment";

import { actions } from "../slice";
import ApiFetchStates from "constants/apiFetchStates";
import { RootState } from "redux-config/store";
import { getTransactions } from "services/businessesService";

const loadTransactions =
  () => async (dispatch: Function, getStates: () => RootState) => {
    try {
      dispatch(actions.setApiFetchStatus(ApiFetchStates.Loading));
      const businessId =
        getStates().dashboardFeature.businesses.selectedBusiness?.id;

      if (!businessId) return;
      const response = await getTransactions(businessId);
      dispatch(
        actions.setData(
          response.transactions.map((d: any) => ({
            timeStamp: moment(d.timestamp).format("YYYY-MM-DD HH:mm:ss"),
            type: d.type,
            amount: _formatAmount(d.amount),
            balance: d.balance,
            email: d.email,
            id: d.id,
          }))
        )
      );
      dispatch(actions.setApiFetchStatus(ApiFetchStates.Done));
    } catch (e) {
      dispatch(actions.setApiFetchStatus(ApiFetchStates.Error));
      console.log(e);
    }
  };

function _formatAmount(value: any) {
  if (isNaN(+value)) return value;

  if (value < 0) return value + "";

  return "+" + value;
}

export default loadTransactions;
