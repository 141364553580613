import { useState, useEffect } from "react";
import { Grid, Typography, Box, Skeleton } from "@mui/material";

// redux
import { useAppSelector, useAppDispatch } from "redux-config/hook";
import transactionActions from "feature/dashboard/redux/transactions/actions";

//components
import PrimaryButton from "components/atoms/Button/PrimaryButton";
import ApiFetchStates from "constants/apiFetchStates";
import TransactionHistoryDialog from "./components/TransactionHistoryDialog";
import TopupStripeDialog from "./components/TopupStripeDialog";
import SnakBar from "components/atoms/SnackBar/SnackBar";

function BusinessBalance() {
  const dispatch = useAppDispatch();
  const { businessInfo: businessInfoStates, businesses } = useAppSelector(
    (state) => state.dashboardFeature
  );

  const [openTopupDia, setOpenTopupDia] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const toggleTopupDial = () => setOpenTopupDia((prev) => !prev);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success") === "false") {
      setOpenSnackBar(true);
    }
  }, []);

  return (
    <>
      <Box>
        <Typography fontSize="30px" fontWeight={600}>
          Business Balance
        </Typography>

        <Box mt={5}></Box>

        {/* Balance */}
        <Box display="flex" justifyContent="space-between" width={"280px"}>
          <Typography fontSize="15px">Remaining balance</Typography>
          {businessInfoStates.apiFetchStatus === ApiFetchStates.Loading && (
            <Skeleton variant="rounded" width={"80px"} height="20px" />
          )}
          {businessInfoStates.apiFetchStatus === ApiFetchStates.Done && (
            <Typography fontSize="16px">{`฿ ${businessInfoStates.businessInfo?.remainingBalance}`}</Typography>
          )}
        </Box>

        <Box mt={3}>
          <PrimaryButton
            text="Top-up"
            btnColor="primary.main"
            sx={{ marginRight: 2 }}
            disabled={
              businessInfoStates.apiFetchStatus === ApiFetchStates.Loading
            }
            onClick={toggleTopupDial}
          />

          <PrimaryButton
            text="Transaction history"
            btnColor="primary.main"
            disabled={
              businessInfoStates.apiFetchStatus === ApiFetchStates.Loading
            }
            onClick={() => dispatch(transactionActions.toggleDialog())}
          />
        </Box>
      </Box>
      <TransactionHistoryDialog />
      <TopupStripeDialog
        open={openTopupDia}
        onClose={toggleTopupDial}
        businessId={businesses.selectedBusiness?.id || ""}
      />

      <SnakBar
        message="Payment unsuccessful. Please Try again."
        open={openSnackBar}
        onClose={setOpenSnackBar.bind(null, false)}
      />
    </>
  );
}

export default BusinessBalance;
