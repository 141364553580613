import axioInstance from "./apiConfig";
import axios from "axios";

async function changePassword(token: string, newPwd: string, oldPwd: string) {
  await axioInstance.post(
    "change_password",
    {
      old_password: oldPwd,
      new_password: newPwd,
    },
    {
      headers: {
        "Content-Type": " application/x-www-form-urlencoded",
      },
    }
  );
}

export default changePassword;
