import { TableCell, TableRow, Typography, Box, Button } from "@mui/material";

interface Props {
  onRetry(): void;
}

function Error(props: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 205,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "customGrey.700",
          fontSize: "18px",
          fontWeight: 600,
        }}
        component={"span"}
      >
        Somthing Went Wrong!
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ mt: 2 }}
        onClick={props.onRetry}
      >
        Try Again
      </Button>
    </Box>
  );
}

export default Error;
